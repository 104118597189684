var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"grid-view wishlist-items"},_vm._l((_vm.products),function(product){return _c('b-card',{key:product.id,staticClass:"ecommerce-card",attrs:{"no-body":""}},[_c('div',{staticClass:"item-img text-center"},[_c('b-link',{attrs:{"to":{
            name: 'apps-e-commerce-product-details',
            params: { slug: product.slug },
          }}},[_c('b-img',{staticClass:"card-img-top",attrs:{"alt":((product.name) + "-" + (product.id)),"fluid":"","src":product.image}})],1)],1),_c('b-card-body',[_c('div',{staticClass:"item-wrapper"},[_c('div',{staticClass:"item-rating"},[_c('ul',{staticClass:"unstyled-list list-inline"},_vm._l((5),function(star){return _c('li',{key:star,staticClass:"ratings-list-item",class:{ 'ml-25': star - 1 }},[_c('feather-icon',{class:[
                    { 'fill-current': star <= product.rating },
                    star <= product.rating ? 'text-warning' : 'text-muted' ],attrs:{"icon":"StarIcon","size":"16"}})],1)}),0)]),_c('div',[_c('h6',{staticClass:"item-price"},[_vm._v(" $"+_vm._s(product.price)+" ")])])]),_c('h6',{staticClass:"item-name"},[_c('b-link',{staticClass:"text-body",attrs:{"to":{
              name: 'apps-e-commerce-product-details',
              params: { slug: product.slug },
            }}},[_vm._v(" "+_vm._s(product.name)+" ")]),_c('b-card-text',{staticClass:"item-company"},[_vm._v(" By "),_c('b-link',{staticClass:"ml-25"},[_vm._v(" "+_vm._s(product.brand)+" ")])],1)],1),_c('b-card-text',{staticClass:"item-description"},[_vm._v(" "+_vm._s(product.description)+" ")])],1),_c('div',{staticClass:"item-options text-center"},[_c('b-button',{staticClass:"btn-wishlist remove-wishlist",attrs:{"variant":"light"},on:{"click":function($event){return _vm.removeProductFromWishlistClick(product)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Remove")])],1),_c('b-button',{staticClass:"btn-cart move-cart",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.handleWishlistCartActionClick(
              product,
              _vm.removeProductFromWishlistClick
            )}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ShoppingCartIcon"}}),_c('span',[_vm._v(_vm._s(product.isInCart ? 'View In Cart' : 'Move to Cart'))])],1)],1)],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }